import Vue from 'vue'
import LightBootstrap from './light-bootstrap-main'

import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store/index';
import router from './routes/router'
import PrimeVue from 'primevue/config';
import'primevue/resources/themes/saga-blue/theme.css';
import'primevue/resources/primevue.min.css';
import'primeicons/primeicons.css';


require('@/store/subscriber');

Vue.use(LightBootstrap);
Vue.use(VueAxios, axios);
Vue.use(PrimeVue);

Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Vue.axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401 && store.getters['auth/authenticated']) {
    store.dispatch("auth/handleUnAuthenticatedResponse").then(() => {
      router.push('/login');
    });
  }
  return Promise.reject(error);
});




store.dispatch("auth/attempt", localStorage.getItem('token')).then(() => {
  store.dispatch("global/index").then(() => {
    new Vue({
      el: '#app',
      render: h => h(App),
      router,
      store
    })
  });
});


