<template>
    <div class="advance-search">

        <filter-organizer class="mt-4 px-4 row" id="element-container"  url=""
        :filters="currentFilter"></filter-organizer>

        <div class="modal-footer">
            <button @click="sendAdvanceSearch" class="btn btn-success">Search</button>
            <button @click="reset" ref="resetBtn" class="btn  btn-danger">Clear</button>
        </div>
    </div>
</template>

<script>
import FilterOrganizer from '../FilterShared/FilterOrganizer.vue';


export default {
  components: { FilterOrganizer },
    props: ["filters", 'url', 'title','id'],
    data() {
        return {
            currentFilter: [],
        }
    },
    mounted() {
        for(let filter of this.filters){
            if(!filter.permession ){
                this.currentFilter.push(filter);
            }

        }
    },
    methods: {
        sendAdvanceSearch: function () {
            this.$emit('filter-data', this.currentFilter);
        },
        reset: function () {
            for(let filter of this.currentFilter){
                filter.value = null ;
            }
            this.$emit('filter-data', this.currentFilter);
        },
    }
}
</script>

