<template>
    <div>
    <template  v-for="(filter, filterIndex) in filters">

            <simple-filter  v-if="filter.frontType === 'simple'"
            class=" mt-4 row" id="simple-filter"
            :filter="filter"></simple-filter>

            <switch-filter v-if="filter.frontType === 'switch'"
             class=" mt-4 row" id="radio-filter"
            :filter="filter"></switch-filter>

            <select-filter v-if="filter.frontType === 'select'"
                     class=" mt-4 row" id="select-filter"
                     :filter="filter"></select-filter>

    </template>
    </div>
</template>


<script>
import SimpleFilter from './SimpleFilter.vue';
import SwitchFilter from './SwitchFilter.vue';
import SelectFilter from './SelectFilter';

export default {
    components: { SimpleFilter, SwitchFilter,SelectFilter },
    props: ["filters"],
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
    }
}
</script>
