import DashboardLayout from "@/pages/layouts/DashboardLayout";

let settingsRoutes = {
  path: '/settings',
  component: DashboardLayout,
  redirect: '/settings/list',
  children: [
    {
      path: 'list',
      name: 'Settings List',
      component: () => import(/* webpackChunkName: "settings" */  '@/pages/general/settings/SettingsList'),
      meta: {
        permissionsCodes: ['ar/settings/index', 'restricted_own/ar/settings/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Settings Item',
      component: () => import(/* webpackChunkName: "settings" */  '@/pages/general/settings/SettingForm'),
      meta: {
        permissionsCodes: ['ar/settings/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Settings Item',
      component: () => import(/* webpackChunkName: "settings" */  '@/pages/general/settings/SettingForm'),
      meta: {
        permissionsCodes: ['ar/settings/update', 'restricted_own/ar/settings/update'],
      }
    }
  ]
}

export default settingsRoutes;
