<template>
  <div>
    <FileUpload  :name="'pdf'" :url="getUploadUrl()"
                 @upload="afterUpload"
                 @before-send="beforeSend"
                 :fileLimit="1"
                 accept=".pdf" :maxFileSize="maxFileSize">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
    <div v-if="value" >
      <a :href="getUrl()+value" target="_blank">
          <i class="nc-icon nc-notes pdf-file-icon" >
          </i>
      </a>
    </div>

    <el-dialog :visible.sync="dialogVisible" @close="closePdfPreview">
      <!--      <video id="video-preview" controls width="650" height="300">-->
      <!--        <source :src="dialogVideoUrl">-->
      <!--        Your browser does not support the video tag.-->
      <!--      </video>-->
    </el-dialog>
  </div>



</template>

<script>

import {Upload, Dialog, Button} from 'element-ui';
import FileUpload from 'primevue/fileupload';


export default {

  name: "PrimePdfUploader",
  props: {
    elementNum:{
      default:1
    },
    maxFileSize:{
      default:1000000
    },
    buttonTitle: {
      default: "Click To Upload"
    },
    value: {},
    previewWidth: {
      type: String,
      default:"240px"
    },
    previewHeight: {
      type: String,
      default:"auto"
    },
  },

  components: {
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    FileUpload,
  },

  data() {
    return {
      dialogVideoUrl: '',
      disabled: false,
      dialogVisible: false
    };
  },
  mounted() {
  },
  methods: {
    beforeSend: function (event) {
      event.xhr.setRequestHeader('Authorization', "Bearer " + this.$store.getters["auth/token"]);
    },
    afterUpload: function (event) {
      let resp = JSON.parse(event.xhr.response);
      this.$emit("input", resp.data.url);
    },
    handleVideoCardPreview() {
      this.dialogVideoUrl = process.env.VUE_APP_SERVER_IMAGE_URL + this.value;
      this.dialogVisible = true;
    },
    handleRemove(x=1000) {
      this.$emit('input', "");
    },
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },

    getUploadUrl(){
      return process.env.VUE_APP_SERVER_IMAGE_URL+'api/files/upload-pdf';
    },
    closePdfPreview(){
      this.dialogVideoUrl = null;
    }
  },
}
</script>
<style scoped>
.el-upload-list__item {
  margin-top: 10px;
}
.pdf-file-icon{
  font-size: 100px;
}
</style>
